import React from 'react'

import ArticleHeader from '../elements/articleHeader'
import Button from '../elements/button'
import { PODLOVE_TESTIMONIALS_DATA } from '../content/podloveTestimonials'
import { getRandomColorName } from '../helpers/colors'

const PodloveTestimonials = () => (
  <div
    id="testimonials"
    className="
      bg-blue-100
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
  >
    <div
      className="
        flex
        flex-wrap
        px-12
        max-w-8xl
      "
    >
      { PODLOVE_TESTIMONIALS_DATA.reviews.map(item =>
        <div
          key={JSON.stringify(item)}
          className="
            px-10
            py-4
            w-full
            md:w-1/2
            xl:w-1/4
          "
        >
          <div
            className={`
              h-10
              items-center
              justify-center
              flex
              my-4
              rounded-md
            `}
          >
            { Array(item.stars).fill().map((item, i) =>
              <img
                className="
                  w-8
                  h-8
                "
                key={i}
                src="/images/icons/star--yellow.png"
              />
            )}
          </div>
          <h3
            className="
              font-bold
              pb-4
              text-xl
            "
          >{item.title}</h3>
          <p
            className="
              mb-4
            "
          >{item.copy}</p>
          <p
            className="
              font-mono
              text-blue-900
            "
          >{item.author}</p>
        </div>
      )}
    </div>
  </div>
)

export default PodloveTestimonials