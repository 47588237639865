import React from 'react'

import Button from '../elements/button'
import { PODLOVE_BENEFITS_DATA } from '../content/podloveBenefits'
import { getRandomColorName } from '../helpers/colors'

const PodloveBenefits = () => (
  <div
    id="features"
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
  >
    <div
      className="
        flex
        flex-wrap
        px-12
        max-w-6xl
      "
    >
      { PODLOVE_BENEFITS_DATA.benefits.map(item =>
        <div
          key={JSON.stringify(item)}
          className="
            text-left
            px-4
            py-12
            w-full
            sm:w-1/2
            lg:w-1/3
          "
        >
          <div
            className={`
              h-10
              items-center
              justify-center
              flex
              my-4
              rounded-md
              text-white
              w-10
            `}
          >
            <img src={item.icon} />
          </div>
          <h3
            className="
              font-bold
              pb-4
              text-xl
            "
          >{item.title}</h3>
          <p
            className="
              mb-4
            "
          >{item.copy}</p>
          <Button
            bgColor={`${getRandomColorName()}-500`}
            bgColorHover={`${getRandomColorName()}-600`}
            label={item.ctaLabel}
            to={item.ctaPath}
          />
        </div>
      )}
    </div>
  </div>
)

export default PodloveBenefits