import React from 'react'

import Layout from '../components/layout'
import PodloveBenefits from '../components/podloveBenefits'
import PodloveCovers from '../components/podloveCovers'
import PodloveTestimonials from '../components/podloveTestimonials'
import Seo from '../components/seo'
import Animation from '../elements/animation'
import WaveAnimation from '../elements/waveAnimation'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div
      className="
        bg-blue-800
        flex
        flex-col
        flex-wrap
        font-sans
        items-center
        justify-center
        px-4
        pb-48
        pt-40
        relative
        text-blue-200
        text-center
        overflow-hidden
        tracking-wide
        w-full
      "
    >
      <Animation />
      <h1
        className="
          font-black
          leading-snug
          max-w-3xl
          mt-8
          text-blue-100
          text-6xl
          z-10
        "
      >Podlove</h1>
      <h2
        className="
          font-light
          leading-snug
          max-w-3xl
          text-blue-100
          text-4xl
          z-10
        "
      >Podcast Publishing made easy</h2>
      <p
        className="
          max-w-2xl
          text-lg
          mt-8
          md:text-xl
          z-10
        "
      >Podlove is a group of Wordpress plugins and allows you to self-host your podcast. With Podlove you become an active part of an open, independent internet. You are in full control of your content and do not depend on a third-party hosting-company.</p>
      <WaveAnimation isMirrored />
    </div>
    <PodloveBenefits />
    <PodloveTestimonials />
    <PodloveCovers />
  </Layout>
)

export default IndexPage
