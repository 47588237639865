export const PODLOVE_BENEFITS_DATA = {
  benefits: [
    {
      copy: 'Once set up the Podlove Publisher lets you release new episodes with elegance and ease. Our clean and slim UI integrates seamlessly into your well known Wordpress environment and makes publishing a podcast as easy as publishing a blogpost.',
      ctaLabel: 'Read more ...',
      ctaPath: '/podlove-podcast-publisher',
      icon: '../images/icons/podlove-publisher.png',
      title: 'Podlove Publisher',
    },
    {
      copy: 'Podlove Web Player is a HTML5 based web player for audio and video media files that is optimized and extended for the specific needs of podcasters. This player is the integrated player for the Podlove Podcast Publisher but can be used independent from it.',
      ctaLabel: 'Read more ...',
      ctaPath: '/podlove-web-player',
      icon: '../images/icons/podlove-web-player.png',
      title: 'Podlove Web Player',
    },
    {
      copy: 'Integrate your RSS-Feed on any website with this simple widget. With one click on the “subscribe”-button it is super easy to pass exactly the right URL to the podcast app of your listeners choice.',
      ctaLabel: 'Read more ...',
      ctaPath: '/podlove-subscribe-button',
      icon: '../images/icons/podlove-subscribe-button.png',
      title: 'Podcast Subscribe Button',
    },
  ]
}