import React from 'react'

import ArticleHeader from '../elements/articleHeader'
import { PODCAST_COVERS_DATA } from '../content/podloveCovers'

const PlayerBenefits = () => (
  <div
    id="features"
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
  >
    <ArticleHeader
      title="Podcasts using Podlove software"
    />
    <div
      className="
        flex
        flex-wrap
        px-12
        max-w-6xl
      "
    >
      { PODCAST_COVERS_DATA.covers.map(item =>
        <div
          key={JSON.stringify(item)}
          className="
            text-left
            px-4
            py-4
            w-full
            sm:w-1/2
            lg:w-1/5
          "
        >
          <a
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={item.image}
              alt={item.alt}
              className="
                shadow-md
              "
            />
          </a>
        </div>
      )}
      <div
        className="
          text-left
          px-4
          py-4
          w-full
          sm:w-1/2
          lg:w-1/5
          flex
        "
      >
        <p className="self-end">and many more...</p>
      </div>
    </div>
  </div>
)

export default PlayerBenefits