export const PODCAST_COVERS_DATA = {
  covers: [
    {
      alt: 'Podcast Cover of CleanElectric',
      image: '../images/podcasts/clean-electric.jpeg',
      url: 'https://www.cleanelectric.de/',
    },
    {
      alt: 'Podcast Cover of Corona Virus Update - NDR Info',
      image: '../images/podcasts/coronavirus-update.jpeg',
      url: 'https://www.ndr.de/nachrichten/info/podcast4684.html',
    },
    {
      alt: 'Podcast Cover of Einschlafen Podcast',
      image: '../images/podcasts/einschlafen.jpeg',
      url: 'http://einschlafen-podcast.de/',
    },
    {
      alt: 'Podcast Cover of Forschergeist',
      image: '../images/podcasts/forschergeist.jpeg',
      url: 'https://forschergeist.de/',
    },
    {
      alt: 'Podcast Cover of Geschichten aus der Geschichte',
      image: '../images/podcasts/geschichten-aus-der-geschichte.jpeg',
      url: 'https://www.geschichte.fm/',
    },
    {
      alt: 'Podcast Cover of Klimabericht - Der Spiegel',
      image: '../images/podcasts/klimabericht.jpeg',
      url: 'https://www.spiegel.de/thema/klimabericht-podcast/',
    },
    {
      alt: 'Podcast Cover of Lage der Nation',
      image: '../images/podcasts/lage-der-nation.jpeg',
      url: 'https://lagedernation.org/',
    },
    {
      alt: 'Podcast Cover of Logbuch: Netzpolitik',
      image: '../images/podcasts/logbuch-netzpolitik.jpeg',
      url: 'https://logbuch-netzpolitik.de/',
    },
    {
      alt: 'Podcast Cover of Spur der Verbrechen',
      image: '../images/podcasts/spur-der-verbrechen.jpeg',
      url: 'https://www.liferadio.at/life-radio-podcasts/spur-der-verbrechen',
    },
    {
      alt: 'Podcast Cover of Staatsbürgerkunde',
      image: '../images/podcasts/staatsbuergerkunde.jpeg',
      url: 'https://www.staatsbuergerkunde-podcast.de/',
    },
    {
      alt: 'Podcast Cover of Wo wir sind ist vorne',
      image: '../images/podcasts/wp-wir-sind-ist-vorne.jpeg',
      url: 'https://wowirsindistvorne.show/',
    },
    {
      alt: 'Podcast Cover of Wrint',
      image: '../images/podcasts/wrint.jpeg',
      url: 'https://wrint.de/',
    },
  ]
}