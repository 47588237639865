export const PODLOVE_TESTIMONIALS_DATA = {
  reviews: [
    {
      author: 'benhartwich',
      copy: 'Great Open Source Plugin for having a free, but complex podcasting suite.',
      stars: 5,
      title: 'Great Plugin',
    },
    {
      author: 'rahra0',
      copy: 'This plugin is really easy to use and works as expected. The podcast pages as well as the podcast feed look very good and contain all information. There’s a good player included and it combines well with the “Podlove Subscribe Button”.',
      stars: 5,
      title: 'easy to use, free, no cloud dependencies',
    },
    {
      author: 'Sven de Vries',
      copy: 'I’ve been using the player for many years. The project is open source and is maintained very lovingly by only a few people. I am thoroughly satisfied.',
      stars: 5,
      title: 'Nice Project',
    },
    {
      author: 'sv3n.spandau',
      copy: 'I’ve blocked an hour this evening to peek into podlove publisher and get an idea of what efforts I will require to publish my (first) podcast. It blew me away, that it took me less than an hour to get all the stuff up and running.',
      stars: 5,
      title: 'Amazing and quick to integrate',
    },
  ]
}